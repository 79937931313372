.source_press_releases { /* stylelint-disable-line */
  .card {
    border-radius: 10px;
  }

  td,
  th {
    padding: 1rem !important;
  }

  .table-striped > tbody > tr:nth-child(odd) > td,
  .table-striped > tbody > tr:nth-child(odd) > th {
    background-color: lighten(#f9f9f9, 10%) !important;
  }

  .status {
    text-align: center;
    font-size: 12px;

    &.warning {
      background: $gold-10;
      border: 1px solid $gold-60;
      border-radius: 60px;
      color: $gold-70;
      width: 35%;
      display: block;
    }

    &.success {
      background: $matcha-10;
      border: 1px solid $matcha-60;
      color: $matcha-70;
      border-radius: 60px;
      width: 35%;
      display: block;
    }

    &.primary {
      background: $periwinkle-10;
      border: 1px solid $periwinkle-60;
      color: $periwinkle-70;
      border-radius: 60px;
      width: 35%;
      display: block;
    }
  }

  a.nav-link.border-0.active {
    background: #f3f4f6;
  }

  .text-silver {
    color: $gray-200;
  }

  .text-gold {
    color: $gold-40;
  }

  .text-platinum {
    color: #81d9dd;
  }

  .boilerplate {
    .tox.tox-tinymce {
      height: 250px !important;
    }
  }

  .orange {
    color: $orange;
  }
}
