// Our own custom colors from Sonia's rebrand guide:
// https://www.figma.com/file/u7m5Yhv6KW0XM3Eq3YgBlw/Rebrand

$coral-90: #5e1107;
$coral-80: #8e1a0a;
$coral-70: #bd220e;
$coral-60: #ec2b11;
$coral-50: #f7553f;
$coral-40: #f47f70;
$coral-30: #f7aa9f;
$coral-20: #fbd5cf;
$coral-10: #fdeae7;
$periwinkle-90: #2e2660;
$periwinkle-80: #463991;
$periwinkle-70: #3a27a5;
$periwinkle-60: #745ff1;
$periwinkle-50: #907ff4;
$periwinkle-40: #ac9ff7;
$periwinkle-30: #c7bff9;
$periwinkle-20: #e3defc;
$periwinkle-10: #f1effe;
$tbn-90: #0a1b33;
$tbn-80: #102d56;
$tbn-70: #163e78;
$tbn-60: #2059aa;
$tbn-50: #4d7abc;
$tbn-40: #799bcd;
$tbn-30: #a6bddd;
$tbn-20: #d2deee;
$tbn-10: #e9eef7;
$raspberry-90: #5c0d1a;
$raspberry-80: #8b1228;
$raspberry-70: #b91835;
$raspberry-60: #e71e42;
$raspberry-50: #eb4b68;
$raspberry-40: #f1788e;
$raspberry-30: #f5a5b3;
$raspberry-20: #fad2d9;
$raspberry-10: #fde9ec;
$matcha-90: #2d330c;
$matcha-80: #4b5515;
$matcha-70: #68761c;
$matcha-60: #869824;
$matcha-50: #a0b23e;
$matcha-40: #b5c369;
$matcha-30: #cad494;
$matcha-20: #dfe5be;
$matcha-15: lighten($matcha-20, 10%);
$matcha-10: #f4f6ea;
$gold-90: #543e12;
$gold-80: #7e5e1c;
$gold-70: #a87d24;
$gold-60: #d29c2e;
$gold-50: #dbb058;
$gold-40: #e4c482;
$gold-30: #edd7ab;
$gold-20: #f6ebd5;
$gold-10: #fbf5e9;
$pepper: #1f2326;
$graphite: #515655;
$cement: #9a9791;
$slate: #cbc5c9;
$fog: #ebebeb;
$shell: #f9f9f9;
$snow: #fff;
$hot-pink: #f70189; // intentionally garish, used internally
$twitter-blue: #1da1f2;

// All colors above in arrays, which can be used programatically such as by:
// [Find closest color from set of colors](https://codepen.io/romainblatrix/pen/YXgBoO)
// Colors: ['5E1107','8E1A0A','BD220E','EC2B11','F7553F','F47F70','F7AA9F','FBD5CF','FDEAE7',
// '463991','5443BD','745FF1','907FF4','AC9FF7','C7BFF9','E3DEFC','F1EFFE','102D56','163E78',
// '2059AA','4D7ABC','799BCD','A6BDDD','D2DEEE','e9eef7','5C0D1A','8B1228','B91835','E71E42',
// 'EB4B68','F1788E','F5A5B3','FAD2D9','FDE9EC','4B5515','68761C','869824','A0B23E','B5C369',
// 'CAD494','DFE5BE','F4F6EA','543E12','7E5E1C','A87D24','D29C2E','DBB058','E4C482','EDD7AB',
// 'F6EBD5','FBF5E9']
// Neutrals: ['1F2326','515655','9a9791','cbc5c9','EBEBEB','F9F9F9','ffffff']

// ----- KT: WHEN UPGRADING BOOTSTRAP, RE-PORT BELOW THIS LINE OVER
// FROM the Bootstrap source, at: node_modules/bootstrap/scss/_variables.scss (COLORS section)

// ----- below default Bootstrap colors

// scss-docs-start gray-color-variables
$white: $snow !default;
$gray-100: $shell !default;
$gray-200: $fog !default;
$gray-300: #dee2e6 !default;
$gray-400: $slate !default;
$gray-500: $cement !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: $graphite !default;
$gray-900: $pepper !default;
$black: #000 !default;

// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;

// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
// $blue: #0d6efd !default; // ORIGINAL
$blue: $tbn-60 !default;

// $indigo: #6610f2 !default; // ORIGINAL
$indigo: $periwinkle-60 !default;

// $purple: #6f42c1 !default; // ORIGINAL
$purple: $periwinkle-80 !default;

// $pink: #d63384 !default; // ORIGINAL
$pink: $raspberry-50 !default;

// $red: #dc3545 !default; // ORIGINAL
$red: $raspberry-60 !default;

// $orange: #fd7e14 !default; // ORIGINAL
$orange: $coral-40 !default;

// $yellow: #ffc107 !default; // ORIGINAL
$yellow: $gold-60 !default;

// $green: #198754 !default; // ORIGINAL
$green: $matcha-60 !default;

// $teal: #20c997 !default; // ORIGINAL
$teal: $matcha-40 !default;

// $cyan: #0dcaf0 !default; // ORIGINAL
$cyan: $tbn-50 !default;
$royal: #2059ab !default;

// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800
) !default;

// scss-docs-end colors-map

// scss-docs-start theme-color-variables
// $primary: $blue !default;
$primary: $periwinkle-70 !default;
$primary-light: $periwinkle-10 !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// KT added custom
$brand: $coral-60 !default;
$garish: $hot-pink !default;
$media: $royal;

// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "brand": $brand,
  "garish": $garish,
  "media": $media,
  "primary-light": $primary-light,
) !default;

// scss-docs-end theme-colors-map
